import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogContent, MatDialogActions, MatDialogClose, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'app-general-comment',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatListModule,
    MatButtonModule,
  ],
  templateUrl: './general-comment.component.html',
  styleUrl: './general-comment.component.scss'
})
export class GeneralCommentComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {comment: string}) {}
}
