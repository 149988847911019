import { IPaginationQuery, PaginationQuery, PaginationQueryBase } from "./pagination-query.model";

export interface ICoordinatorAdminServiceRequestPaginationQuery {
	requestNumber: number | null;
	status: string | null;
	serviceRequestType: string | null;
  acceptedReason: string | null;
	pagination: IPaginationQuery;
}

export class CoordinatorAdminServiceRequestPaginationQuery extends PaginationQueryBase {
	requestNumber: number | null;
	status: string | null;
	serviceRequestType: string | null;
  acceptedReason: string | null;

	constructor(serviceRequest: ICoordinatorAdminServiceRequestPaginationQuery | null) {
		super();
		this.requestNumber = serviceRequest?.requestNumber ?? null;
		this.status = serviceRequest?.status ?? null;
		this.serviceRequestType = serviceRequest?.serviceRequestType ?? null;
    this.acceptedReason = serviceRequest?.acceptedReason ?? null;
	}

	static create(query: ICoordinatorAdminServiceRequestPaginationQuery): CoordinatorAdminServiceRequestPaginationQuery {
		let serviceRequestQuery = new CoordinatorAdminServiceRequestPaginationQuery(query);
		serviceRequestQuery.pagination = new PaginationQuery(query.pagination);

		return serviceRequestQuery;
	}
}
