<mat-dialog-content class="pb-0">
  <h4 mat-dialog-title>Comments</h4>
  <div>{{ data.comment }}</div>
  <mat-divider></mat-divider>
  <br>
</mat-dialog-content>

<mat-dialog-actions class="mx-4">
  <button mat-flat-button color="accent" [mat-dialog-close]>Close</button>
</mat-dialog-actions>
