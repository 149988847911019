import { Component } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { PaginatedServiceRequest } from '../../../models/service-request.models';
import { ServiceRequestService } from '../../../services/service-request.service';
import { RouterService } from '../../../services/router.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NavigationExtras } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ServiceRequestPaginationQuery } from '../../../models/query-models/service-request-pagination-query.model';
import { PaginatedComponent } from '../../../models/paginated.component';
import { paths } from '../../../../../../../src/clients/backend/backend-openapi-schema';
import { PathsWithMethod } from '../../../services/paginated.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PrettyConstantPipe } from '../../../utils/extensions/pretty-constant-pipe';
import { MatDialog } from '@angular/material/dialog';
import { CancelServiceRequestComponent } from '../../dialogs/cancel-service-request/cancel-service-request.component';
import { FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AcceptedReasonsComponent } from '../../dialogs/accepted-reasons/accepted-reasons.component';
import { MatSelectModule } from '@angular/material/select';
import { ServiceCategory } from '../../../models/service-category.models';
import { ServiceCategoryService } from '../../../services/service-category.service';
import { allAcceptedProviderReason } from '../../../utils/constants/select-provider-reasons.constants';

@Component({
  selector: 'app-service-requests',
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    CommonModule,
    MatTooltipModule,
    MatPaginatorModule,
    PrettyConstantPipe,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule
  ],
  templateUrl: './admin-service-requests.component.html',
  styleUrl: './admin-service-requests.component.scss'
})
export class AdminServiceRequestsComponent extends PaginatedComponent<PaginatedServiceRequest, ServiceRequestPaginationQuery> {
  filterServiceRequestForm!: FormGroup;
  serviceTypes: ServiceCategory[] = []
    providerAcceptedReasons: string[] = allAcceptedProviderReason;

  override tblQuery: ServiceRequestPaginationQuery = ServiceRequestPaginationQuery.create({
    requestNumber: null,
    serviceType: null,
    status: null,
    acceptedReason: null,
    pagination: {
      page: 0,
      pageSize: 25,
      sortAscending: false,
      sortField: "createdDate"
    }
  });

  override buildQuery(): ServiceRequestPaginationQuery {
    return this.tblQuery;
  }

  override buildColumnHeaders(): string[] {
    return ['serviceRequestNumber', 'createdDate', 'mci', 'firstName', 'lastName', 'service', 'selectedProviders', 'selectedProvidersReason', 'status', 'createdByUser', 'options'];
  }

  override buildRoute(): PathsWithMethod<paths, 'post'> {
    return "/ServiceRequest/GetServiceRequestsForAdministrator";
  }

  constructor(
    private serviceRequestService: ServiceRequestService,
    private serviceCategoryService: ServiceCategoryService,
    private routerService: RouterService,
    private dialog: MatDialog,
  ) {
    super(serviceRequestService);
  }

  override ngOnInit(): void {
    this.filterServiceRequestForm = new FormGroup({
      serviceRequestNumber: new FormControl<number | null>(null),
      serviceType: new FormControl<string | null>(null),
      acceptedReason: new FormControl<string | null>(null),
    });

    this.getServiceTypes();
  }

  async getServiceTypes() {
    this.serviceTypes = await this.serviceCategoryService.getServiceCategory();
  }

  onFilterClick() {
    this.tblQuery.requestNumber = this.filterServiceRequestForm.controls['serviceRequestNumber']?.value;
    this.tblQuery.serviceType = this.filterServiceRequestForm.controls['serviceType']?.value;
    this.tblQuery.acceptedReason = this.filterServiceRequestForm.controls['acceptedReason']?.value;

    if (this.tblQuery.requestNumber?.toString() == "") {
      this.tblQuery.requestNumber = null;
    }
    if (this.tblQuery.serviceType?.toString() == "") {
      this.tblQuery.serviceType = null;
    }
    if (this.tblQuery.acceptedReason?.toString() == "") {
      this.tblQuery.acceptedReason = null;
    }

    this.getData(null);
  }

  createClick() {
    this.routerService.goToUpsertServiceRequestScreen(null);
  }

  editClick(id: string) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        "id": id
      }
    };

    this.routerService.goToUpsertServiceRequestScreen(navigationExtras);
  }

  viewProvidersClick(id: string) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        "id": id
      }
    };

    this.routerService.goToServiceRequestProviderList(navigationExtras);
  }

  async cancelServiceRequestClick(id: string) {
    const dialogRef = this.dialog.open(CancelServiceRequestComponent, {});

    dialogRef.afterClosed().subscribe(async result => {
      if (result !== null && result !== undefined) {
        await this.serviceRequestService.cancelServiceRequest(id, result);

        window.location.reload();
      }
    });
  }

  viewAuditTable(id: string) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        "id": id
      }
    };

    this.routerService.goToServiceRequestAuditTable(navigationExtras);
  }

  viewAcceptedReason(serviceRequest: PaginatedServiceRequest) {
    var providers = serviceRequest.providers;

    if(serviceRequest.status === 'Closed'){
      providers = serviceRequest.providers.filter(p => p.status === 7)
    }

    this.dialog.open(AcceptedReasonsComponent, {
      data: {
        providers: providers
      }
    });
  }

  getFirstSelectedProvider (providers: any): string {
    let providerName = "";

    for (const provider of providers) {
      if (provider.status === 7) {
        providerName = provider.provider.providerAgency + "...";
        break;
      }
    }

    return providerName;
  }

  getFirstSelectedProviderAcceptedReason (providers: any): string {
    let acceptedReason = "";

    for (const provider of providers) {
      if (provider.status === 7) {
        if (provider.acceptedReason != null) {
          acceptedReason = provider.acceptedReason + "...";
        }
        break;
      }
    }

    return acceptedReason;
  }
}
