import { Component } from '@angular/core';
import { PaginatedAuditServiceRequest } from '../../../../../models/service-request.models';
import { MatTableModule } from '@angular/material/table';
import { PaginatedComponent } from '../../../../../models/paginated.component';
import { AuditServiceRequestPaginationQuery } from '../../../../../models/query-models/service-request-pagination-query.model';
import { PathsWithMethod } from '../../../../../services/paginated.service';
import { paths } from '../../../../../../../../../src/clients/backend/backend-openapi-schema';
import { ActivatedRoute } from '@angular/router';
import { ServiceRequestAuditService } from '../../../../../services/service-request-audit.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PrettyConstantPipe } from '../../../../../utils/extensions/pretty-constant-pipe';
import { CommonModule } from '@angular/common';
import { GeneralCommentComponent } from '../../../../dialogs/general-comment/general-comment.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-audit-table',
  standalone: true,
  imports: [
    MatTableModule,
    MatPaginatorModule,
    PrettyConstantPipe,
    CommonModule
  ],
  templateUrl: './audit-table.component.html',
  styleUrl: './audit-table.component.scss'
})
export class AuditTableComponent extends PaginatedComponent<PaginatedAuditServiceRequest, AuditServiceRequestPaginationQuery> {
  override tblQuery: AuditServiceRequestPaginationQuery = AuditServiceRequestPaginationQuery.create({
    serviceRequestId: null,
    pagination: {
      page: 0,
      pageSize: 25,
      sortAscending: true,
      sortField: "CreatedAt"
    }
  });

  override buildQuery(): AuditServiceRequestPaginationQuery {
    return this.tblQuery;
  }

  override buildColumnHeaders(): string[] {
    return ['serviceRequestNumber', 'firstName', 'lastName', 'providerAgency', 'requestType', 'type', 'cancelReason', 'createdAt'];
  }

  override buildRoute(): PathsWithMethod<paths, 'post'> {
    return "/ServiceRequest/GetLogsForServiceRequest";
  }

  constructor(
    private serviceRequestAuditService: ServiceRequestAuditService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    super(serviceRequestAuditService);
  }

  override ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(async params => {
      this.tblQuery.serviceRequestId = params["id"];
    });
  }

  viewComments(serviceRequest: PaginatedAuditServiceRequest, comment: string) {
    if(serviceRequest.type !== 'ServiceRequestCancelled') {
      return;
    }

    this.dialog.open(GeneralCommentComponent, {
      data: {
        comment: comment,
      }
    });
  }
}
