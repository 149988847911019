@if (this.serviceRequest !== null && !isLoading) {
  <div class="flex items-center justify-center mx-40 my-5">
    <div class="flex-row w-3/5 justify-start m-1">
      <h3 class="mb-0">Service Details For Service Request #{{ serviceRequest.serviceRequestNumber + " - " + serviceRequest.firstName + " " + serviceRequest.lastName }}</h3>
      <mat-divider class="w-full mb-1"></mat-divider>
      <div class="m-1 border-solid border-2 border-stone-300 rounded">
        <p class="m-1 text-gray-400">Request Info</p>
        <mat-divider class="mx-1"></mat-divider>
        <div class="m-2">
          <b>Name:</b>
          {{
            this.serviceRequest.firstName + " " + this.serviceRequest.lastName
          }}
        </div>
        <div class="m-2">
          <b>MCI #:</b>
          {{ this.serviceRequest.mci }}
        </div>
        <div class="m-2">
          @if (this.serviceRequest.requestType === 'EI') {
            <b>Proposal Date:</b>
          } @else {
            <b>Respond By Date:</b>
          }
          {{ this.providerInterestResponseDate.toLocaleDateString() }}
        </div>
        <div class="m-2">
          <b>Proposal Response Date:</b>
          {{ this.providerProposalResponseDate.toLocaleDateString() }}
        </div>
        <div class="m-2">
          <b>Preferred Start Date:</b>
          {{ this.preferredStartDate?.toLocaleDateString() }}
        </div>
        <div class="m-2">
          <b>Service Category:</b>
          @for (category of serviceRequest.categories; track category) {
            {{ category.name }}
          }
        </div>
        <div class="m-2">
          <b>Location:</b>
          {{ this.serviceRequest.serviceLocation }}
        </div>
        <div class="m-2">
          <b>Frequency & Duration:</b>
          {{ this.serviceRequest.frequencyAndDuration }}
        </div>
        <div class="m-2">
          <b>Coordinator:</b>
          {{ this.serviceRequest.coordinatorEmail }}
        </div>
        <div class="m-2">
          <b>Additional Comments:</b>
          {{ this.serviceRequest.comments }}
        </div>
      </div>
      <div class="m-1 border-solid border-2 border-stone-300 rounded">
        <mat-divider class="mx-1"></mat-divider>
        <div>
          <mat-form-field class="w-full">
            <mat-label>Comments</mat-label>
            <textarea matInput [formControl]="comments"></textarea>
          </mat-form-field>
        </div>

        <div class="grid grid-cols-4">
          <div class="col-span-4">
            <p class="m-1 text-gray-400">Attachments</p>
            <mat-divider class="mx-1 mb-2"></mat-divider>
          </div>

          <div class="col-span-2">
            <app-attachments-list
              [serviceRequestAttachmentsForProviderReadOnly]="
                serviceRequestAttachments
              "
              [serviceRequestProviderAttachments]="providerAttachments"
              [newAttachments]="newAttachments"
              [disabled]="isDisabled"
              (removeNewAttachmentEmit)="removeFile($event)"
            ></app-attachments-list>
          </div>

          <div class="col-span-2">
            <app-file-uploader
              [disabled]="isDisabled"
              (uploadFileEmit)="this.newAttachments.push($event)"
            ></app-file-uploader>
          </div>
        </div>
      </div>

      <div>
        <div class="flex flex-row-reverse m-1 gap-2">
          <button
            mat-flat-button
            color="primary"
            (click)="submitInterestClick()"
            *ngIf="!isDisabled"
          >
            Submit Interest
          </button>

          @if (serviceRequest.providers[0]?.status === "ProposalRequested") {
            <button mat-flat-button color="primary" (click)="this.createProposalClick()">
              <span>Create Proposal</span>
            </button>
          }

          @if (serviceRequest.providers[0]?.status !== "Withdrawn" && serviceRequest.status !== "Closed" && serviceRequest.status !== "Cancelled") {
            <button mat-flat-button color="accent" class="mr-auto" (click)="openWithdrawDialog()">
              Withdraw
            </button>
          }

          <button
            mat-flat-button
            color="accent"
            (click)="routerService.goToProviderServiceRequestsScreen()"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
}
