export const selectProviderReasons: string[] = [
    "EI Rotation",
    "EI Family Choice",
    "IDA",
];

export const allAcceptedProviderReason: string [] = [
  ...selectProviderReasons,
  'Selected without proposal',
];
