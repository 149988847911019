<mat-dialog-content class="pb-0">
  <h4 mat-dialog-title>Provider Accepted Reasons</h4>

  @for (provider of data.providers; track provider) {
    <h5>{{ provider.provider.providerAgency }}</h5>
    @if (provider.status === 7) {
      <div>{{ provider.acceptedReason }}</div>
    } @else {
      <div>Not Selected</div>
    }
    <mat-divider></mat-divider>
  }
</mat-dialog-content>

<mat-dialog-actions class="mx-4">
  <button mat-flat-button color="accent" [mat-dialog-close]>Close</button>
</mat-dialog-actions>
